import Flatpickr from 'stimulus-flatpickr'
import { German } from 'flatpickr/dist/l10n/de.js'
import 'flatpickr/dist/themes/material_blue.css'

export default class extends Flatpickr {
    static values = {
        autoSubmit: Boolean
    }

    initialize() {
        this.config = {
            locale: German
        }
    }

    change(selectedDates, dateStr, instance) {
        if (this.autoSubmitValue) {
            if ('range' === this.config.mode) {
                if (selectedDates.length > 1) {
                    this.element.closest('form').requestSubmit();
                }
            } else {
                this.element.closest('form').requestSubmit();
            }
        }
    }
}
