import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['form', 'title'];
    static values = {
        reload: { type: Boolean, default: false }
    }

    connect() {
        // Remove disabled attributes, as everything is now initialized
        this.element.querySelectorAll('[disabled]').forEach((e) => {
            e.removeAttribute('disabled');
        });
    }

    submit(event) {
        event.preventDefault();

        this.element.classList.add('searching');

        const form = event.currentTarget;

        fetch(form.action, {
            method: 'POST', 
            body: new URLSearchParams(new FormData(form)),
            headers: {
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
        }).then((response) => {
            if (!response.ok) {
                this.element.classList.remove('searching');

                response.json().then((response) => {
                    if (response.message) {
                        this.element.querySelector('li.title').innerHTML = response.message;
                    }
                });

                this.element.querySelector('.quickadd-title').style.display = 'none';
                this.element.querySelector('li.price').innerHTML = '';
                this.element.querySelector('li.quantity').innerHTML = '';
                this.element.querySelector('li.position figure')?.remove();
            } else {
                response.json().then((response) => {
                    this.element.classList.remove('searching');
                    this.titleTarget.style.display = 'none';

                    if (response.thumbnail) {
                        this.element.querySelector('li.position figure')?.remove();
                        this.element.querySelector('li.position').innerHTML += response.thumbnail;
                    }

                    if (response.name) {
                        this.element.querySelector('li.title').innerHTML = response.name;
                    }

                    if (response.price) {
                        this.element.querySelector('li.price').innerHTML = response.price;
                    }

                    if (response.quantity) {
                        this.element.querySelector('li.quantity').innerHTML = response.quantity;
                        this.element.querySelector('li.quantity input.multi').focus();
                        this.element.querySelector('li.quantity input.multi').select();
                        document.dispatchEvent(new CustomEvent('triggerContentUpdated', { detail: this.element.querySelector('li.quantity') }));

                        // init quickadd-quantity form submit
                        this.element.querySelector('.unitswitch--form form').addEventListener('submit', (event) => {
                            event.preventDefault();

                            const unitswitchForm = event.target;
                            const unitswitch = unitswitchForm.closest('.unitswitch');

                            form.querySelector('input.quickadd-sku').value = '';
                            form.querySelector('input.quickadd-sku').focus();

                            this.element.querySelector('.quickadd-title').style.display = 'block';
                            this.element.querySelector('li.title').innerHTML = '';
                            this.element.querySelector('li.price').innerHTML = '';
                            this.element.querySelector('li.quantity').innerHTML = '';
                            this.element.querySelector('li.position figure')?.remove();

                            // remove previous item, if present
                            if (typeof unitswitch.dataset.productId !== 'undefined') {
                                document.querySelector('.cart-table-items .item[data-product-id="'+unitswitch.dataset.productId+'"]')?.remove();
                            }

                            // add dummy item
                            const template = document.createElement('template');
                            template.innerHTML = '<li class="item dummy"><div class="row-container"><ul class="properties"><li><i class="btr bt-2x bt-spinner bt-pulse"></i></li></ul></div></li>';
                            const dummy = template.content.children[0];
                            const cartTableItems = document.querySelector('.cart-table-items');

                            if (null !== document.querySelector('.cart-table-items')) {
                                cartTableItems.prepend(dummy);
                            }

                            // perform ajax cart request
                            const url = unitswitchForm.action;
                            KastnerCart.queueRequest(url, Object.fromEntries(new FormData(unitswitchForm)), 'POST', () => {
                                dummy.remove();

                                if (this.reloadValue) {
                                    window.location.reload();
                                }
                            });

                            // don't actually submit form
                            return false;
                        });
                    }

                    if (response.signal) {
                        this.element.setAttribute('data-signal', response.signal);
                    }
                });
            }
        });

        return false;
    }

    add(event) {
        event.preventDefault();

        const unitswitch = this.element.querySelector('li.quantity form');

        if (null !== unitswitch) {
            unitswitch.requestSubmit();
        } else {
            this.formTarget.requestSubmit();
        }

        return false;
    }
}
