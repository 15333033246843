import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['item'];

    itemTargetConnected(element) {
        tippy(element);
    }
}
