import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    add(e) {
        const button = e.currentTarget;
        const multi = button.dataset.multi;
        const unit = button.dataset.unit;

        if (!multi || !unit) {
            return;
        }

        const item = button.closest('.item');

        if (!item) {
            return;
        }

        const input = item.querySelector('input[name="multi"]');
        const radioGeneral = item.querySelector('input[name="unit"][value="general"]');
        const radioDetail = item.querySelector('input[name="unit"][value="detail"]');
        const unitswitch = item.querySelector('.unitswitch--form');

        if (!input) {
            return;
        }

        if (null !== radioGeneral && unit === 'general' && input.value < multi) {
            input.value = multi;
            radioGeneral.checked = true;
            unitswitch.setAttribute('data-current', unit);
            input.dispatchEvent(new Event('input'));
            return;
        }

        if (null !== radioDetail && unit === 'detail' && ((radioDetail.checked && input.value < multi) || !input.value)) {
            input.value = multi;
            radioDetail.checked = true;
            unitswitch.setAttribute('data-current', unit);
            input.dispatchEvent(new Event('input'));
            return;
        }
    }
}
