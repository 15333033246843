import '@hotwired/turbo'
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

import './css/styles.scss';

// Start Stimulus application
const application = Application.start()
const context = require.context('./controllers', true, /\.js$/)
application.load(definitionsFromContext(context));
application.debug = process.env.NODE_ENV === 'development';

// Trigger "contentUpdated" event after turbo:frame-render
document.addEventListener('turbo:frame-render', (e) => {
    document.dispatchEvent(new CustomEvent('triggerContentUpdated', { detail: e.target }));
});
